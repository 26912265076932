
import { Options, Vue } from 'vue-class-component';
import { generateLogo } from '@/utils/logo';
import { Watch, Prop } from 'vue-property-decorator';
import { Values } from '@/Values';

@Options({})
export default class ManinMenu extends Vue {
    @Prop({ required: true })
    private values!: Values;

    private previewURL: string|null = null;

    public created (): void {
        this.redrawPreview();
    }

    public beforeUnmount (): void {
        if (this.previewURL) URL.revokeObjectURL(this.previewURL);
    }

    @Watch('values.mainMenuLogo', { deep: true })
    private async redrawPreview () {
        const { text, fontSize } = this.values.mainMenuLogo;

        const logo = await generateLogo(text, { fontSize });

        if (this.previewURL !== null) URL.revokeObjectURL(this.previewURL);
        this.previewURL = URL.createObjectURL(logo);
    }
}
