
import { Options, Vue } from 'vue-class-component';
import { Values } from '@/Values';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class General extends Vue {
    @Prop({ required: true })
    private values!: Values;

    private author = '';

    /**
     * Callback of "add"-button or KeyDown.Enter of
     * author input
     */
    private addAuthor () {
        this.values.authors.push(this.author);
        this.author = '';
    }

    /**
     * Remove author from list
     * @param {string} author Author to remove
     */
    private removeAuthor (author: string) {
        this.values.authors = this.values.authors.filter(x => x !== author);
    }

    private get descriptionPlaceholder () {
        if (this.values.fullName.length === 0) return 'Gruppe Adler Sling Helmet...';

        return `Gruppe Adler ${this.values.fullName}...`;
    }
}
