
import { Options, Vue } from 'vue-class-component';
import { Values } from '@/Values';

interface Step {
    icon: string;
    text: string;
    done: boolean;
}

@Options({})
export default class App extends Vue {
    private steps: { [key: string]: Step|undefined } = {
        general: {
            icon: 'edit',
            text: 'Allgemeines',
            done: false
        },
        main_menu: {
            icon: 'image',
            text: 'Hauptmenü',
            done: false
        },
        custom_adler: {
            icon: 'add_photo_alternate',
            text: 'Adler',
            done: false
        },
        verify: {
            icon: 'rule',
            text: 'Überprüfen',
            done: false
        },
        done: {
            icon: 'check',
            text: 'Fertig',
            done: false
        }
    };

    private values: Values = {
        fullName: '',
        gitHubRepo: '',
        description: '',
        authors: [] as string[],
        uploadedAdler: null,
        mainMenuLogo: {
            text: '',
            fontSize: 27
        }
    };

    private currentStep = 'general';

    public created (): void {
        this.$router.afterEach((to) => {
            this.currentStep = to.path.substr(1);
        });
    }

    private stepClasses (id: string): string {
        if (this.currentStep === id) return 'steps--active';
        if (this.steps[id]?.done) return 'steps--done';

        return '';
    }

    private get previousShown (): boolean {
        const keys = Object.keys(this.steps);
        return keys.indexOf(this.currentStep) !== 0;
    }

    private get nextShown (): boolean {
        const keys = Object.keys(this.steps);
        return keys.indexOf(this.currentStep) !== keys.length - 1;
    }

    private previous () {
        const keys = Object.keys(this.steps);
        const index = keys.indexOf(this.currentStep);
        const previousStep = keys[index - 1];
        this.$router.push(previousStep);
    }

    private next () {
        const keys = Object.keys(this.steps);
        const index = keys.indexOf(this.currentStep);
        const nextStep = keys[index + 1];
        this.$router.push(nextStep);
    }
}
