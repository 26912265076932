
import { Options, Vue } from 'vue-class-component';
import { Values } from '@/Values';
import { Prop, Watch } from 'vue-property-decorator';
import { generatePseudoCustomAdler } from '@/utils/pseudoCustomAdler';

@Options({})
export default class CustomAdler extends Vue {
    @Prop({ required: true })
    private values!: Values;

    private previewURL: string|null = null;

    public created (): void {
        this.redrawPreview();
    }

    public beforeUnmount (): void {
        if (this.previewURL) URL.revokeObjectURL(this.previewURL);
    }

    private onAdlerFileChanged (e: Event) {
        const input = e.target as HTMLInputElement;
        if (!input.files) return;

        const files = Array.from(input.files);
        if (files.length === 0) return;

        this.values.uploadedAdler = files[0];
    }

    @Watch('values.fullName')
    @Watch('values.uploadedAdler')
    private async redrawPreview () {
        const logo = this.values.uploadedAdler ?? await generatePseudoCustomAdler(this.values.fullName);

        if (this.previewURL !== null) URL.revokeObjectURL(this.previewURL);
        this.previewURL = URL.createObjectURL(logo);
    }
}
